export default [
  {
    path: '/learning-chat-contents',
    component: () => import('./views/Index'),
    name: 'learning_chat_content',
    redirect: { name: 'learning_chat_content-list' },
    children: [
      {
        path: '',
        name: 'learning_chat_content-list',
        component: () => import('./views/List'),
        meta: {
          resource: 'learning.chat_content',
        },
      },
      {
        path: 'bot-messages',
        name: 'learning_chat_content-list_bot',
        component: () => import('./views/ListBotContent'),
        meta: {
          resource: 'learning.chat_content',
        },
      },
      {
        path: 'user-messages',
        name: 'learning_chat_content-list_user',
        component: () => import('./views/ListUserContent'),
        meta: {
          resource: 'learning.chat_content',
        },
      },
      {
        path: ':id',
        name: 'learning_chat_content-detail',
        component: () => import('./views/Detail'),
        meta: {
          resource: 'learning.chat_content',
        },
      },
    ],
  },
]
