<template>
  <section class="position-fixed round shadow"
    style="top: 153px; right: 1px; background-color: #fff; padding: 0.6rem"
  >
    <template v-if="backButton">
      <b-button variant="secondary" size="sm" block
        @click="$router.go(-1)"
        class="action-btn"
      >
        Back
      </b-button>
    </template>

    <template v-if="updateButton && write_permission">
      <hr>
      <b-button :disabled="isDisabledUpdate" variant="success" size="sm" block class="mt-1 action-btn"
        @click="$emit('update')"
      >
        <b-spinner v-if="isUpdating" small />
        Save
      </b-button>
    </template>

    <template v-if="deleteButton && write_permission">
      <hr>
      <delete-button size="sm" @delete="$emit('delete')" class="action-btn">
        <b-spinner v-if="isDeleting" small />
        Delete
      </delete-button>
    </template>

    <template>
      <hr>
      <action-record-button size="sm" @openActionTableModal="openActionTableModal" class="action-btn">
        Logs
      </action-record-button>
    </template>
    <action-table-modal ref="modalActionTable" />
  </section>
</template>
<script>
import DeleteButton from './DeleteButton.vue'
import ActionRecordButton from './ActionRecordButton.vue'
import ActionTableModal from './../../../../modules/action_record/views/_components/ActionTableModal.vue'
import useWritePermission from '@core/comp-functions/useWritePermission'

export default {
  name: 'ActionButtons',
  components: {
    DeleteButton,
    ActionRecordButton,
    ActionTableModal
  },
  props: {
    updateButton: { type: Boolean, default: false },
    backButton: { type: Boolean, default: false },
    deleteButton: { type: Boolean, default: false },
    isUpdating: { type: Boolean, default: false },
    isDeleting: { type: Boolean, default: false },
    isDisabledUpdate: { type: Boolean, default: false }
  },
  setup(props, ctx) {
    let { write_permission } = useWritePermission(ctx.root?.$route);
    return { write_permission };
  },
  computed: {
    documentId() {
      const params = this.$route.params
      return Object.keys(params)[0] ? params[Object.keys(params)[0]] : ''
    },
  },
  methods: {
    openActionTableModal(){
      this.$refs.modalActionTable.openActionTableModal(this.documentId)
    }
  }
}
</script>
<style>
.action-btn {
  padding: 5px 5px !important;
  display: block;
  width: 100%;
}
</style>
