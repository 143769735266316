export default [
  {
    path: '/config',
    component: () => import('./views/Index'),
    name: '',
    redirect: { name: 'learning-config' },
    children: [
      {
        path: 'learning-config',
        name: 'learning-config',
        component: () => import('./views/Detail'),
        meta: {
          resource: 'learning.lesson_config',
        },
      },
    ],
  },
]
