export default [
  {
    path: '/topic',
    component: () => import('./views/Index'),
    name: 'learning_topic',
    redirect: { name: 'learning_topic-list' },
    children: [
      {
        path: '',
        name: 'learning_topic-list',
        component: () => import('./views/List'),
        meta: {
          resource: 'learning.main_topics',
        },
      },
      {
        path: ':id',
        name: 'learning_topic-detail',
        component: () => import('./views/Detail'),
        meta: {
          resource: 'learning.main_topics',
        },
      },
      {
        path: '/lesson-filtered/:id',
        name: 'lesson-filterd-by-topic',
        component: () => import('./views/ListLessonFiltered'),
        meta: {
          resource: 'learning.main_topics',
        },
      }
    ],
  },
]
