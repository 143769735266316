<template>
  <div>
    <b-card>
      <b-col cols="12">
        <b-form-group label="Language" label-for="language">
          <language-select
            :language="language"
            @selectedLanguage="selectedLanguage"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group>
          <ui-component-list v-bind="$attrs" :component-list="translation.content" />
        </b-form-group>
      </b-col>
    </b-card>
  </div>
</template>

<script>
export default {
  props: {
    componentData: { type: Object, required: true },
  },
  data() {
    return {
      language: "vi",
      translation: {
        content: [],
      },
    };
  },
  created() {
    this.setLanguage();
    this.bindSelectedTranslation();
  },
  methods: {
    bindSelectedTranslation() {
      let existedTranslation = this.componentData.translations.find(
        (item) => item.language == this.language
      );
      if (!existedTranslation) {
        existedTranslation = { language: this.language, content: [] };
        this.componentData.translations.push(existedTranslation);
      }
      this.translation = existedTranslation;
    },
    selectedLanguage(language) {
      this.language = language;
      this.bindSelectedTranslation();
    },
    setLanguage() {
      this.language = this.$store.getters["auth/language"];
    },
  },
};
</script>
<style scoped>
.card {
  margin-bottom: 0 !important;
}
</style>
